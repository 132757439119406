import { useEffect } from 'react';
import sync, { cancelSync } from 'framesync';

export default (callback) => {
  useEffect(() => {
    const render = sync.render(() => {
      if (typeof callback === 'function') {
        callback();
      }
    }, true);
    return () => {
      cancelSync.render(render);
    };
  }, [callback]);
};
