import { useEffect, useRef } from 'react';
import { useStore } from 'src/components/GlobalState';
import sync, { cancelSync } from 'framesync';

export default (options = {}) => {
  const elementRef = useRef();
  const scrollRef = useRef(useStore.getState().scroll);
  const reflow = useStore((state) => state.reflow);
  const reflowBoundsRef = useRef();
  const boundsRef = useRef();

  useEffect(
    () =>
      useStore.subscribe(
        (scroll) => (scrollRef.current = scroll),
        (state) => state.scroll
      ),
    []
  );

  useEffect(() => {
    if (elementRef.current) {
      reflowBoundsRef.current = elementRef.current.getBoundingClientRect();
    }
  }, [reflowBoundsRef, elementRef, reflow]);

  useEffect(() => {
    const update = sync.update(() => {
      boundsRef.current = new DOMRect(
        reflowBoundsRef.current.x + (reflow.x - scrollRef.current.x),
        reflowBoundsRef.current.y + (reflow.y - scrollRef.current.y),
        reflowBoundsRef.current.width,
        reflowBoundsRef.current.height
      );
    }, true);
    return () => {
      cancelSync.update(update);
    };
  }, [boundsRef, reflowBoundsRef, reflow, scrollRef, options]);

  return [elementRef, boundsRef];
};
